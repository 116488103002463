<template>
  <el-select-v2
      v-model="interModel"
      :multiple="!!multiple"
      filterable
      clearable
      @change="change || (() => {})"
      class="full-width"
      style="min-width: 200px"
      :options="userOptions"
      :disabled="disabled || false"
  >
  </el-select-v2>
</template>

<script>


export default {
  name: "ClientSelect",
  props: ["change", "fullWidth", "multiple", "withAll", "hideInactive", "modelValue", "disabled"],
  emits: ['update:modelValue'],

  watch: {

  },
  computed: {
    userOptions(){
      let opts = this.$store.getters.clients.filter(c => {
        if(!this.hideInactive) return true
        return c.active === 1 && c.balance && !c.balance.blocked
      }).map(c => {
        return {
          label: `${c.system_id} | ${c.info.user_type === 'physic' ? `${c.info.lastname} ${c.info.firstname} ${c.info.patronymic}` : `${c.info.legal_form || ""} ${c.info.company_name}`} | ${c.info.email} | ${c.info.phone}`,
          value: c.system_id
        }
      })
      if(this.withAll) opts.unshift({label : "Все", value:""})
      return opts
    },
    interModel: {
      get(){
        return this.modelValue
      },
      set(v){
        this.$emit("update:modelValue", v)
      }
    }
  },

  data() {
    return {
    }
  },
}

</script>